<template>
    <v-snackbar v-model="snackbarVisible" :timeout="snackbarTimeout" :color="snackbarColor">
        {{ snackbarMessage }}
    </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "ErrorPopup",
    computed: {
        ...mapState('api', {
            snackbarVisible: (state) => state.snackbar.visible,
            snackbarMessage: (state) => state.snackbar.message,
            snackbarColor: (state) => state.snackbar.color,
            snackbarTimeout: (state) => state.snackbar.timeout,
        }),
    },
    watch: {
        snackbarVisible(newValue) {
            if (newValue) {
                // Start a timeout to hide the snackbar after snackbarTimeout milliseconds
                setTimeout(() => {
                    this.$store.commit('api/hideSnackbar');
                }, this.snackbarTimeout);
            }
        }
    },
};
</script>
