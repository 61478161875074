<template>
  <DataTabs />
</template>
    
<script>
import DataTabs from "../components/layout/MultiStageSeperatorTest/DataTabs.vue";
export default {
  components: { DataTabs },
  name: "MultiStageSeparatorTest",
};
</script>