module.exports = [
    {
        "Default": true,
        "FluidSampleID": "RECOMBINED",
        "Custodian": "WFT",
        "Representative": "NC",
        "SampleDate": "2014-01-13",
        "Remark": "SEPARATOR",
        "AcquisitionTemperature": "113.0",
        "AcquisitionTemperature_UOM": "degF",
        "AcquisitionPressure": "570.0",
        "AcquisitionPressure_UOM": "psi",
        "FormationTemperature": "246.2",
        "FormationTemperature_UOM": "degF",
        "FormationPressure": "8731.3",
        "FormationPressure_UOM": "psi",
        "DLTest": {
            "TestTemperature": 246.2,
            "TestTemperature_UOM": "degF",
            "SampleID": "RECOMBINED",
            "SaturationPressure": 3862.0,
            "SaturationPressure_UOM": "psi",
            "DLTestStep": [
                {
                    "StepPressure": "6013.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.4895",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "4.1803",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "4.1803",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "5092.79",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "0.0",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": null,
                    "GasGravityCumulative": null,
                    "GasDensity": null,
                    "GasDensity_UOM": null,
                    "GasZFactor": null,
                    "GasFormationVolumeFactor": null,
                    "GasExpansionFactor": null,
                    "OilViscosity_x": null,
                    "OilViscosity_UOM_x": null,
                    "GasViscosity_x": null,
                    "GasViscosity_UOM_x": null,
                    "StepPressure_UOM_y": null,
                    "OilViscosity_y": null,
                    "OilViscosity_UOM_y": null,
                    "GasViscosity_y": null,
                    "GasViscosity_UOM_y": null
                },
                {
                    "StepPressure": "5513.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.4813",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "4.2509",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "4.2509",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "5092.79",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "0.0",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": null,
                    "GasGravityCumulative": null,
                    "GasDensity": null,
                    "GasDensity_UOM": null,
                    "GasZFactor": null,
                    "GasFormationVolumeFactor": null,
                    "GasExpansionFactor": null,
                    "OilViscosity_x": null,
                    "OilViscosity_UOM_x": null,
                    "GasViscosity_x": null,
                    "GasViscosity_UOM_x": null,
                    "StepPressure_UOM_y": null,
                    "OilViscosity_y": null,
                    "OilViscosity_UOM_y": null,
                    "GasViscosity_y": null,
                    "GasViscosity_UOM_y": null
                },
                {
                    "StepPressure": "5013.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.4721",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "4.3344",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "4.3344",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "5092.79",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "0.0",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": null,
                    "GasGravityCumulative": null,
                    "GasDensity": null,
                    "GasDensity_UOM": null,
                    "GasZFactor": null,
                    "GasFormationVolumeFactor": null,
                    "GasExpansionFactor": null,
                    "OilViscosity_x": null,
                    "OilViscosity_UOM_x": null,
                    "GasViscosity_x": null,
                    "GasViscosity_UOM_x": null,
                    "StepPressure_UOM_y": null,
                    "OilViscosity_y": null,
                    "OilViscosity_UOM_y": null,
                    "GasViscosity_y": null,
                    "GasViscosity_UOM_y": null
                },
                {
                    "StepPressure": "4763.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.4668",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "4.3835",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "4.3835",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "5092.79",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "0.0",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": null,
                    "GasGravityCumulative": null,
                    "GasDensity": null,
                    "GasDensity_UOM": null,
                    "GasZFactor": null,
                    "GasFormationVolumeFactor": null,
                    "GasExpansionFactor": null,
                    "OilViscosity_x": null,
                    "OilViscosity_UOM_x": null,
                    "GasViscosity_x": null,
                    "GasViscosity_UOM_x": null,
                    "StepPressure_UOM_y": null,
                    "OilViscosity_y": null,
                    "OilViscosity_UOM_y": null,
                    "GasViscosity_y": null,
                    "GasViscosity_UOM_y": null
                },
                {
                    "StepPressure": "4513.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.4611",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "4.4373",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "4.4373",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "5092.79",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "0.0",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": null,
                    "GasGravityCumulative": null,
                    "GasDensity": null,
                    "GasDensity_UOM": null,
                    "GasZFactor": null,
                    "GasFormationVolumeFactor": null,
                    "GasExpansionFactor": null,
                    "OilViscosity_x": null,
                    "OilViscosity_UOM_x": null,
                    "GasViscosity_x": null,
                    "GasViscosity_UOM_x": null,
                    "StepPressure_UOM_y": null,
                    "OilViscosity_y": null,
                    "OilViscosity_UOM_y": null,
                    "GasViscosity_y": null,
                    "GasViscosity_UOM_y": null
                },
                {
                    "StepPressure": "4263.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.455",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "4.4971",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "4.4971",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "5092.79",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "0.0",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": null,
                    "GasGravityCumulative": null,
                    "GasDensity": null,
                    "GasDensity_UOM": null,
                    "GasZFactor": null,
                    "GasFormationVolumeFactor": null,
                    "GasExpansionFactor": null,
                    "OilViscosity_x": null,
                    "OilViscosity_UOM_x": null,
                    "GasViscosity_x": null,
                    "GasViscosity_UOM_x": null,
                    "StepPressure_UOM_y": null,
                    "OilViscosity_y": null,
                    "OilViscosity_UOM_y": null,
                    "GasViscosity_y": null,
                    "GasViscosity_UOM_y": null
                },
                {
                    "StepPressure": "4013.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.4483",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "4.5639",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "4.5639",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "5092.79",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "0.0",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": null,
                    "GasGravityCumulative": null,
                    "GasDensity": null,
                    "GasDensity_UOM": null,
                    "GasZFactor": null,
                    "GasFormationVolumeFactor": null,
                    "GasExpansionFactor": null,
                    "OilViscosity_x": null,
                    "OilViscosity_UOM_x": null,
                    "GasViscosity_x": null,
                    "GasViscosity_UOM_x": null,
                    "StepPressure_UOM_y": null,
                    "OilViscosity_y": null,
                    "OilViscosity_UOM_y": null,
                    "GasViscosity_y": null,
                    "GasViscosity_UOM_y": null
                },
                {
                    "StepPressure": "3863.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.4439",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "4.6088",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "4.6088",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "5092.79",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "0.0",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": null,
                    "GasGravityCumulative": null,
                    "GasDensity": null,
                    "GasDensity_UOM": null,
                    "GasZFactor": null,
                    "GasFormationVolumeFactor": null,
                    "GasExpansionFactor": null,
                    "OilViscosity_x": "0.108",
                    "OilViscosity_UOM_x": "cP",
                    "GasViscosity_x": "",
                    "GasViscosity_UOM_x": "cp",
                    "StepPressure_UOM_y": "psi",
                    "OilViscosity_y": "0.108",
                    "OilViscosity_UOM_y": "cP",
                    "GasViscosity_y": "",
                    "GasViscosity_UOM_y": "cp"
                },
                {
                    "StepPressure": "3413.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.4859",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "3.2904",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "4.9501",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "3168.57",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "1924.22",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": "1.0135",
                    "GasGravityCumulative": "1.0135",
                    "GasDensity": "0.2549",
                    "GasDensity_UOM": "g\\/cm3",
                    "GasZFactor": "0.8311",
                    "GasFormationVolumeFactor": "0.0048",
                    "GasExpansionFactor": "206.490",
                    "OilViscosity_x": "0.124",
                    "OilViscosity_UOM_x": "cP",
                    "GasViscosity_x": "0.02851",
                    "GasViscosity_UOM_x": "cp",
                    "StepPressure_UOM_y": "psi",
                    "OilViscosity_y": "0.124",
                    "OilViscosity_UOM_y": "cP",
                    "GasViscosity_y": "0.02851",
                    "GasViscosity_UOM_y": "cp"
                },
                {
                    "StepPressure": "3013.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.5239",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "2.7746",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "5.3345",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "2446.75",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "2646.04",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": "0.9508",
                    "GasGravityCumulative": "0.9984",
                    "GasDensity": "0.2131",
                    "GasDensity_UOM": "g\\/cm3",
                    "GasZFactor": "0.8233",
                    "GasFormationVolumeFactor": "0.0054",
                    "GasExpansionFactor": "184.100",
                    "OilViscosity_x": "0.141",
                    "OilViscosity_UOM_x": "cP",
                    "GasViscosity_x": "0.02428",
                    "GasViscosity_UOM_x": "cp",
                    "StepPressure_UOM_y": "psi",
                    "OilViscosity_y": "0.141",
                    "OilViscosity_UOM_y": "cP",
                    "GasViscosity_y": "0.02428",
                    "GasViscosity_UOM_y": "cp"
                },
                {
                    "StepPressure": "2613.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.5537",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "2.4707",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "5.8964",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "2019.98",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "3072.81",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": "0.9047",
                    "GasGravityCumulative": "0.9867",
                    "GasDensity": "0.1759",
                    "GasDensity_UOM": "g\\/cm3",
                    "GasZFactor": "0.8233",
                    "GasFormationVolumeFactor": "0.0063",
                    "GasExpansionFactor": "159.756",
                    "OilViscosity_x": "0.163",
                    "OilViscosity_UOM_x": "cP",
                    "GasViscosity_x": "0.02126",
                    "GasViscosity_UOM_x": "cp",
                    "StepPressure_UOM_y": "psi",
                    "OilViscosity_y": "0.163",
                    "OilViscosity_UOM_y": "cP",
                    "GasViscosity_y": "0.02126",
                    "GasViscosity_UOM_y": "cp"
                },
                {
                    "StepPressure": "2213.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.579",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "2.2545",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "6.8097",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "1671.97",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "3420.82",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": "0.8636",
                    "GasGravityCumulative": "0.9754",
                    "GasDensity": "0.1404",
                    "GasDensity_UOM": "g\\/cm3",
                    "GasZFactor": "0.8336",
                    "GasFormationVolumeFactor": "0.0075",
                    "GasExpansionFactor": "133.748",
                    "OilViscosity_x": "0.185",
                    "OilViscosity_UOM_x": "cP",
                    "GasViscosity_x": "0.01894",
                    "GasViscosity_UOM_x": "cp",
                    "StepPressure_UOM_y": "psi",
                    "OilViscosity_y": "0.185",
                    "OilViscosity_UOM_y": "cP",
                    "GasViscosity_y": "0.01894",
                    "GasViscosity_UOM_y": "cp"
                },
                {
                    "StepPressure": "1813.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.6003",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "2.0957",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "8.152",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "1408.95",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "3683.84",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": "0.8521",
                    "GasGravityCumulative": "0.9673",
                    "GasDensity": "0.1121",
                    "GasDensity_UOM": "g\\/cm3",
                    "GasZFactor": "0.8442",
                    "GasFormationVolumeFactor": "0.0092",
                    "GasExpansionFactor": "108.332",
                    "OilViscosity_x": "0.204",
                    "OilViscosity_UOM_x": "cP",
                    "GasViscosity_x": "0.01732",
                    "GasViscosity_UOM_x": "cp",
                    "StepPressure_UOM_y": "psi",
                    "OilViscosity_y": "0.204",
                    "OilViscosity_UOM_y": "cP",
                    "GasViscosity_y": "0.01732",
                    "GasViscosity_UOM_y": "cp"
                },
                {
                    "StepPressure": "1413.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.6174",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "1.9641",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "10.3835",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "1173.88",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "3918.91",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": "0.8547",
                    "GasGravityCumulative": "0.9611",
                    "GasDensity": "0.0859",
                    "GasDensity_UOM": "g\\/cm3",
                    "GasZFactor": "0.8616",
                    "GasFormationVolumeFactor": "0.0121",
                    "GasExpansionFactor": "82.899",
                    "OilViscosity_x": "0.222",
                    "OilViscosity_UOM_x": "cP",
                    "GasViscosity_x": "0.01601",
                    "GasViscosity_UOM_x": "cp",
                    "StepPressure_UOM_y": "psi",
                    "OilViscosity_y": "0.222",
                    "OilViscosity_UOM_y": "cP",
                    "GasViscosity_y": "0.01601",
                    "GasViscosity_UOM_y": "cp"
                },
                {
                    "StepPressure": "1013.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.6387",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "1.8547",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "14.5352",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "955.01",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "4137.78",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": "0.8788",
                    "GasGravityCumulative": "0.9571",
                    "GasDensity": "0.0617",
                    "GasDensity_UOM": "g\\/cm3",
                    "GasZFactor": "0.8842",
                    "GasFormationVolumeFactor": "0.0172",
                    "GasExpansionFactor": "58.116",
                    "OilViscosity_x": "0.244",
                    "OilViscosity_UOM_x": "cP",
                    "GasViscosity_x": "0.01489",
                    "GasViscosity_UOM_x": "cp",
                    "StepPressure_UOM_y": "psi",
                    "OilViscosity_y": "0.244",
                    "OilViscosity_UOM_y": "cP",
                    "GasViscosity_y": "0.01489",
                    "GasViscosity_UOM_y": "cp"
                },
                {
                    "StepPressure": "613.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.6579",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "1.7065",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "24.2743",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "719.81",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "4372.98",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": "0.9645",
                    "GasGravityCumulative": "0.9575",
                    "GasDensity": "0.0399",
                    "GasDensity_UOM": "g\\/cm3",
                    "GasZFactor": "0.9083",
                    "GasFormationVolumeFactor": "0.029",
                    "GasExpansionFactor": "34.511",
                    "OilViscosity_x": "0.272",
                    "OilViscosity_UOM_x": "cP",
                    "GasViscosity_x": "0.01377",
                    "GasViscosity_UOM_x": "cp",
                    "StepPressure_UOM_y": "psi",
                    "OilViscosity_y": "0.272",
                    "OilViscosity_UOM_y": "cP",
                    "GasViscosity_y": "0.01377",
                    "GasViscosity_UOM_y": "cp"
                },
                {
                    "StepPressure": "213.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.6851",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "1.5545",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "70.0627",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "469.05",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "4623.74",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": "1.2757",
                    "GasGravityCumulative": null,
                    "GasDensity": "0.0177",
                    "GasDensity_UOM": "g\\/cm3",
                    "GasZFactor": "0.9408",
                    "GasFormationVolumeFactor": null,
                    "GasExpansionFactor": "12.020\\n",
                    "OilViscosity_x": "0.33",
                    "OilViscosity_UOM_x": "cP",
                    "GasViscosity_x": "0.01204",
                    "GasViscosity_UOM_x": "cp",
                    "StepPressure_UOM_y": "psi",
                    "OilViscosity_y": "0.33",
                    "OilViscosity_UOM_y": "cP",
                    "GasViscosity_y": "0.01204",
                    "GasViscosity_UOM_y": "cp"
                },
                {
                    "StepPressure": "13.0",
                    "StepPressure_UOM_x": "psi",
                    "OilDensity": "0.7318",
                    "OilDensity_UOM": "g\\/cm3",
                    "OilFormationVolumeFactor": "1.1036",
                    "OilFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "TotalFormationVolumeFactor": "694.5468",
                    "TotalFormationVolumeFactor_UOM": "bbl\\/bbl",
                    "SolutionGORMeasured": "0.0",
                    "SolutionGORMeasured_UOM": "scf\\/STB",
                    "CumulativeStockTankGOR": "5092.79",
                    "CumulativeStockTankGOR_UOM": "scf\\/STB",
                    "GasGravity": null,
                    "GasGravityCumulative": null,
                    "GasDensity": null,
                    "GasDensity_UOM": null,
                    "GasZFactor": null,
                    "GasFormationVolumeFactor": null,
                    "GasExpansionFactor": null,
                    "OilViscosity_x": "0.584",
                    "OilViscosity_UOM_x": "cP",
                    "GasViscosity_x": "0.00891",
                    "GasViscosity_UOM_x": "cp",
                    "StepPressure_UOM_y": "psi",
                    "OilViscosity_y": "0.584",
                    "OilViscosity_UOM_y": "cP",
                    "GasViscosity_y": "0.00891",
                    "GasViscosity_UOM_y": "cp"
                },
                {
                    "StepPressure": "4913.0",
                    "StepPressure_UOM_x": null,
                    "OilDensity": null,
                    "OilDensity_UOM": null,
                    "OilFormationVolumeFactor": null,
                    "OilFormationVolumeFactor_UOM": null,
                    "TotalFormationVolumeFactor": null,
                    "TotalFormationVolumeFactor_UOM": null,
                    "SolutionGORMeasured": null,
                    "SolutionGORMeasured_UOM": null,
                    "CumulativeStockTankGOR": null,
                    "CumulativeStockTankGOR_UOM": null,
                    "GasGravity": null,
                    "GasGravityCumulative": null,
                    "GasDensity": null,
                    "GasDensity_UOM": null,
                    "GasZFactor": null,
                    "GasFormationVolumeFactor": null,
                    "GasExpansionFactor": null,
                    "OilViscosity_x": "0.113",
                    "OilViscosity_UOM_x": "cP",
                    "GasViscosity_x": "",
                    "GasViscosity_UOM_x": "cp",
                    "StepPressure_UOM_y": "psi",
                    "OilViscosity_y": "0.113",
                    "OilViscosity_UOM_y": "cP",
                    "GasViscosity_y": "",
                    "GasViscosity_UOM_y": "cp"
                },
                {
                    "StepPressure": "4613.0",
                    "StepPressure_UOM_x": null,
                    "OilDensity": null,
                    "OilDensity_UOM": null,
                    "OilFormationVolumeFactor": null,
                    "OilFormationVolumeFactor_UOM": null,
                    "TotalFormationVolumeFactor": null,
                    "TotalFormationVolumeFactor_UOM": null,
                    "SolutionGORMeasured": null,
                    "SolutionGORMeasured_UOM": null,
                    "CumulativeStockTankGOR": null,
                    "CumulativeStockTankGOR_UOM": null,
                    "GasGravity": null,
                    "GasGravityCumulative": null,
                    "GasDensity": null,
                    "GasDensity_UOM": null,
                    "GasZFactor": null,
                    "GasFormationVolumeFactor": null,
                    "GasExpansionFactor": null,
                    "OilViscosity_x": "0.111",
                    "OilViscosity_UOM_x": "cP",
                    "GasViscosity_x": "",
                    "GasViscosity_UOM_x": "cp",
                    "StepPressure_UOM_y": "psi",
                    "OilViscosity_y": "0.111",
                    "OilViscosity_UOM_y": "cP",
                    "GasViscosity_y": "",
                    "GasViscosity_UOM_y": "cp"
                },
                {
                    "StepPressure": "4313.0",
                    "StepPressure_UOM_x": null,
                    "OilDensity": null,
                    "OilDensity_UOM": null,
                    "OilFormationVolumeFactor": null,
                    "OilFormationVolumeFactor_UOM": null,
                    "TotalFormationVolumeFactor": null,
                    "TotalFormationVolumeFactor_UOM": null,
                    "SolutionGORMeasured": null,
                    "SolutionGORMeasured_UOM": null,
                    "CumulativeStockTankGOR": null,
                    "CumulativeStockTankGOR_UOM": null,
                    "GasGravity": null,
                    "GasGravityCumulative": null,
                    "GasDensity": null,
                    "GasDensity_UOM": null,
                    "GasZFactor": null,
                    "GasFormationVolumeFactor": null,
                    "GasExpansionFactor": null,
                    "OilViscosity_x": "0.109",
                    "OilViscosity_UOM_x": "cP",
                    "GasViscosity_x": "",
                    "GasViscosity_UOM_x": "cp",
                    "StepPressure_UOM_y": "psi",
                    "OilViscosity_y": "0.109",
                    "OilViscosity_UOM_y": "cP",
                    "GasViscosity_y": "",
                    "GasViscosity_UOM_y": "cp"
                }
            ],
            "DLTestStepComposition": [
                {
                    "StepPressure": "213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0004",
                    "FluidComponentReference": "n2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "13.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0001",
                    "FluidComponentReference": "n2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0033",
                    "FluidComponentReference": "n2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0033",
                    "FluidComponentReference": "n2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0033",
                    "FluidComponentReference": "n2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0033",
                    "FluidComponentReference": "n2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1813.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0029",
                    "FluidComponentReference": "n2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0025",
                    "FluidComponentReference": "n2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0021",
                    "FluidComponentReference": "n2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0014",
                    "FluidComponentReference": "n2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0083",
                    "FluidComponentReference": "co2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "13.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0036",
                    "FluidComponentReference": "co2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0055",
                    "FluidComponentReference": "co2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0057",
                    "FluidComponentReference": "co2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0059",
                    "FluidComponentReference": "co2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0063",
                    "FluidComponentReference": "co2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1813.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0066",
                    "FluidComponentReference": "co2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0069",
                    "FluidComponentReference": "co2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0073",
                    "FluidComponentReference": "co2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0081",
                    "FluidComponentReference": "co2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0",
                    "FluidComponentReference": "h2s",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "13.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0",
                    "FluidComponentReference": "h2s",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0",
                    "FluidComponentReference": "h2s",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0",
                    "FluidComponentReference": "h2s",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0",
                    "FluidComponentReference": "h2s",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0",
                    "FluidComponentReference": "h2s",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1813.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0",
                    "FluidComponentReference": "h2s",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0",
                    "FluidComponentReference": "h2s",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0",
                    "FluidComponentReference": "h2s",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0",
                    "FluidComponentReference": "h2s",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.3464",
                    "FluidComponentReference": "c1",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "13.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0756",
                    "FluidComponentReference": "c1",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.6554",
                    "FluidComponentReference": "c1",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.6779",
                    "FluidComponentReference": "c1",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.6918",
                    "FluidComponentReference": "c1",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.7024",
                    "FluidComponentReference": "c1",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1813.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.7005",
                    "FluidComponentReference": "c1",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.6896",
                    "FluidComponentReference": "c1",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.6604",
                    "FluidComponentReference": "c1",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.5761",
                    "FluidComponentReference": "c1",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.2407",
                    "FluidComponentReference": "c2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "13.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0849",
                    "FluidComponentReference": "c2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.1324",
                    "FluidComponentReference": "c2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.1324",
                    "FluidComponentReference": "c2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.135",
                    "FluidComponentReference": "c2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.1387",
                    "FluidComponentReference": "c2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1813.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.1432",
                    "FluidComponentReference": "c2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.1514",
                    "FluidComponentReference": "c2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.1657",
                    "FluidComponentReference": "c2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.1982",
                    "FluidComponentReference": "c2",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.1847",
                    "FluidComponentReference": "c3",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "13.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.1293",
                    "FluidComponentReference": "c3",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0748",
                    "FluidComponentReference": "c3",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0715",
                    "FluidComponentReference": "c3",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0708",
                    "FluidComponentReference": "c3",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0709",
                    "FluidComponentReference": "c3",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1813.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0725",
                    "FluidComponentReference": "c3",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0767",
                    "FluidComponentReference": "c3",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0858",
                    "FluidComponentReference": "c3",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.1131",
                    "FluidComponentReference": "c3",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0337",
                    "FluidComponentReference": "i-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "13.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0392",
                    "FluidComponentReference": "i-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0129",
                    "FluidComponentReference": "i-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0121",
                    "FluidComponentReference": "i-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0117",
                    "FluidComponentReference": "i-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0111",
                    "FluidComponentReference": "i-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1813.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0111",
                    "FluidComponentReference": "i-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0115",
                    "FluidComponentReference": "i-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0127",
                    "FluidComponentReference": "i-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.017",
                    "FluidComponentReference": "i-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0784",
                    "FluidComponentReference": "n-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "13.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.1053",
                    "FluidComponentReference": "n-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0302",
                    "FluidComponentReference": "n-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0274",
                    "FluidComponentReference": "n-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0258",
                    "FluidComponentReference": "n-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0246",
                    "FluidComponentReference": "n-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1813.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0246",
                    "FluidComponentReference": "n-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0251",
                    "FluidComponentReference": "n-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0275",
                    "FluidComponentReference": "n-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0366",
                    "FluidComponentReference": "n-c4",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0244",
                    "FluidComponentReference": "i-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "13.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0519",
                    "FluidComponentReference": "i-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0103",
                    "FluidComponentReference": "i-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.009",
                    "FluidComponentReference": "i-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.008",
                    "FluidComponentReference": "i-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0072",
                    "FluidComponentReference": "i-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1813.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0071",
                    "FluidComponentReference": "i-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0069",
                    "FluidComponentReference": "i-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0083",
                    "FluidComponentReference": "i-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0112",
                    "FluidComponentReference": "i-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0282",
                    "FluidComponentReference": "n-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "13.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0713",
                    "FluidComponentReference": "n-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0121",
                    "FluidComponentReference": "n-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0105",
                    "FluidComponentReference": "n-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0092",
                    "FluidComponentReference": "n-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.008",
                    "FluidComponentReference": "n-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1813.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0079",
                    "FluidComponentReference": "n-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0076",
                    "FluidComponentReference": "n-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0089",
                    "FluidComponentReference": "n-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0128",
                    "FluidComponentReference": "n-c5",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0235",
                    "FluidComponentReference": "c6",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "13.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.1089",
                    "FluidComponentReference": "c6",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0138",
                    "FluidComponentReference": "c6",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0313",
                    "FluidComponentReference": "C7+",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "13.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.3299",
                    "FluidComponentReference": "C7+",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0493",
                    "FluidComponentReference": "C7+",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "3013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0377",
                    "FluidComponentReference": "C7+",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0288",
                    "FluidComponentReference": "C7+",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "2213.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0197",
                    "FluidComponentReference": "C7+",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1813.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0158",
                    "FluidComponentReference": "C7+",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1413.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0143",
                    "FluidComponentReference": "C7+",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "1013.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0131",
                    "FluidComponentReference": "C7+",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                },
                {
                    "StepPressure": "613.0",
                    "StepPressure_UOM": "psi",
                    "Vapor_MoleFraction()": "0.0149",
                    "FluidComponentReference": "C7+",
                    "Vapor_MoleFraction()_UOM": "%[molar]"
                }
            ],
            "DLResidComposition": [
                {
                    "FluidComponentReference": "n2",
                    "Liquid_MoleFraction": "0.0",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "co2",
                    "Liquid_MoleFraction": "0.0",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "h2s",
                    "Liquid_MoleFraction": "0.0",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c1",
                    "Liquid_MoleFraction": "0.0",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c2",
                    "Liquid_MoleFraction": "0.0",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c3",
                    "Liquid_MoleFraction": "0.0017",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0004",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "i-c4",
                    "Liquid_MoleFraction": "0.0014",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0005",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "n-c4",
                    "Liquid_MoleFraction": "0.0068",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0022",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "i-c5",
                    "Liquid_MoleFraction": "0.0095",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0037",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "n-c5",
                    "Liquid_MoleFraction": "0.0154",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0061",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c6",
                    "Liquid_MoleFraction": "0.025",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0118",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c7",
                    "Liquid_MoleFraction": "0.0633",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0348",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c8",
                    "Liquid_MoleFraction": "0.0936",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0586",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c9",
                    "Liquid_MoleFraction": "0.0856",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0602",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c10",
                    "Liquid_MoleFraction": "0.0725",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0566",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "C11",
                    "Liquid_MoleFraction": "0.0681",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0549",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c12",
                    "Liquid_MoleFraction": "0.0553",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0488",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c13",
                    "Liquid_MoleFraction": "0.0576",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0553",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c14",
                    "Liquid_MoleFraction": "0.0478",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0499",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c15",
                    "Liquid_MoleFraction": "0.039",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.044",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c16",
                    "Liquid_MoleFraction": "0.0296",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.036",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c17",
                    "Liquid_MoleFraction": "0.0272",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0354",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c18",
                    "Liquid_MoleFraction": "0.0256",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0353",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c19",
                    "Liquid_MoleFraction": "0.023",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0332",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c20",
                    "Liquid_MoleFraction": "0.0186",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0281",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c21",
                    "Liquid_MoleFraction": "0.0161",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0258",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c22",
                    "Liquid_MoleFraction": "0.0144",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0241",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c23",
                    "Liquid_MoleFraction": "0.0127",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0221",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c24",
                    "Liquid_MoleFraction": "0.0113",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0205",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c25",
                    "Liquid_MoleFraction": "0.0103",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0195",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c26",
                    "Liquid_MoleFraction": "0.0089",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0174",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c27",
                    "Liquid_MoleFraction": "0.0076",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0156",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c28",
                    "Liquid_MoleFraction": "0.0067",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0142",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c29",
                    "Liquid_MoleFraction": "0.0055",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0121",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "c30+",
                    "Liquid_MoleFraction": "0.0366",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.1163",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "C5H10",
                    "Liquid_MoleFraction": "0.0014",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0005",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "C6H12",
                    "Liquid_MoleFraction": "0.0087",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.004",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "C6H12",
                    "Liquid_MoleFraction": "0.0095",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0044",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "C7H14",
                    "Liquid_MoleFraction": "0.0346",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0187",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "C6H6",
                    "Liquid_MoleFraction": "0.0013",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0006",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "C7H8",
                    "Liquid_MoleFraction": "0.0107",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0054",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "C8H10",
                    "Liquid_MoleFraction": "0.0117",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0068",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "C8H10",
                    "Liquid_MoleFraction": "0.0095",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0055",
                    "Liquid_MassFraction_UOM": "%[mass]"
                },
                {
                    "FluidComponentReference": "C9H12",
                    "Liquid_MoleFraction": "0.0159",
                    "Liquid_MoleFraction_UOM": "%[molar]",
                    "Liquid_MassFraction": "0.0105",
                    "Liquid_MassFraction_UOM": "%[mass]"
                }
            ]
        }
    }
]