<template>
  <div :ref="chartId"></div>
</template>

<script>
import Plotly from "plotly.js-dist-min";

export default {
  name: "TwoDHistogramChart",
  data() {
    return {
      chartId: '2dhistogramChart'
    };
  },
  props: {
    xAxisData: { Array, default: () => [] },
    yAxisData: { Array, default: () => [] },
  },
  watch: {
    xAxisData: function(newX, oldX) {
      this.updateChart();
    },
    yAxisData: function(newY, oldY) {
      this.updateChart();
    },
  },
  methods: {
    updateChart() {

      const traces = this.yAxisData.map((yData, index) => ({
        x: this.xAxisData,
        y: yData,
        type: "histogram2d",
        name: `Line ${index + 1}`,
      }));


      var layout = {
        title: "2D Histogram Chart",
        xaxis: { title: "StepPressure" },
        yaxis: { title: "OilDensity" },
      };
      var config = {responsive: true}
      Plotly.newPlot(this.$refs[this.chartId], traces, layout, config);
    },
  },
  mounted() {
    this.updateChart();
  },
};
</script>