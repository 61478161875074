<template>
    <PreviewTables/>
  </template>
      
  <script>
  import PreviewTables from "../../components/layout/AIML/PreviewTables.vue";
  export default {
    components: { PreviewTables },
    name: "DataPreview",
  };
  </script>