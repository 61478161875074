<template>
  <InsightCharts />
</template>

<script>
import InsightCharts from '../../components/layout/EDA/InsightCharts.vue'
export default {
  components: { InsightCharts },

}
</script>