<template>
  <div class="data-provided-wrapper">
    <div class="wrapper-content">
      <h6 class="main-heading">Select Data from Database</h6>
      <v-select placeholder="Select a report to begin" :items="items"></v-select>
      <v-btn block class="primary-btn">
        <span>Next</span>
      </v-btn>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "DataSelect",
  data: () => ({
    items: ["California", "Colorado", "Florida", "Georgia", "Texas", "Wyoming"],
  }),
};
</script>