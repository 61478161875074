import axios from 'axios';

// Cache Storage key
const CACHE_NAME = 'metaDataCache';
const CACHE_KEY = 'http://localhost:8080/metaData'; // Use the full URL as the key

// Function to fetch and store metadata in the cache
export async function fetchMetaData() {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}public/api/v1/configurationsets`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          "X-TenantID": localStorage.getItem("tenantId"),
        },
      }
    );

    if (response.data.statusCode === 200) {
      const data = response.data.data.configuration_sets;
      const cache = await caches.open(CACHE_NAME);
      const timestampedData = { data, timestamp: new Date().getTime() };
      const cacheResponse = new Response(JSON.stringify(timestampedData), {
        headers: { 'Content-Type': 'application/json' }
      });
      await cache.put(CACHE_KEY, cacheResponse);
      return data;
    } else {
      throw new Error(`Unexpected status code: ${response.data.statusCode}`);
    }
  } catch (error) {
    console.error("Error fetching metadata:", error);
    throw error;
  }
}

// Function to get cached metadata without opening a new cache
export async function getCachedMetaData() {
  if (!caches) {
    return null;
  }

  const cache = await caches.open(CACHE_NAME);

  const cachedResponse = await cache.match(CACHE_KEY);
  if (!cachedResponse) {
    return null;
  }

  const cachedData = await cachedResponse.json();
  return cachedData.data;
}

// Function to get display name of a field
export async function getDisplayName(tableName, fieldName) {
  let metaData = await getCachedMetaData();

  if (!metaData || !metaData[0]) {
    return { displayName: fieldName, digits: undefined };
  }

  const table = metaData[0].tables.find(table => table.name === tableName);
  if (!table) {
    return { displayName: fieldName, digits: undefined };
  }

  const field = table.fields.find(asset => asset.field === fieldName);
  if (!field) {
    return { displayName: fieldName, digits: undefined };
  }

  return { displayName: field.displayName, digits: field.digits };
}