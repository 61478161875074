<template>
  <v-row no-gutters>
    <v-col v-for="colIndex in numberOfColumns" :key="colIndex" cols="12" md="6" lg="3">
      <v-sheet class="mr-2 mb-2 border">
        <v-skeleton-loader
          class="mx-auto"
          type="article"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto"
          type="article"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto"
          type="article"
        ></v-skeleton-loader>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "GridLoaders",
  data() {
    return {
      numberOfColumns: 8,
    };
  },
};
</script>
