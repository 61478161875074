<template>
  <ViscosityData />
</template>
    
<script>
import ViscosityData from '@/components/layout/OilViscosity/ViscosityData.vue';
export default {
  components: { ViscosityData },
  name: "OilViscosity",
};
</script>