<template>
  <div class="px-4">
    <h4>Selected PVT Properties</h4>
    <v-chip
      label
      style="width: 100%; font-weight: 600"
      >Predict selected PVT properties (From Composition and CCE Input)</v-chip
    >
    <v-table>
      <thead>
        <tr>
          <th class="text-center">Sr No.</th>
          <th class="text-center">Prediction of Selected PVT Properties</th>
          <th class="text-center">Units</th>
          <th class="text-center">Confidence in Prediction</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in selected_pvt" :key="item.name"></tr>
      </tbody>
    </v-table>
  </div>
</template>
  
  <script>
export default {
  name: "SelectedPVT",
  data() {
    return {
      selected_pvt: [],
    };
  },
};
</script>