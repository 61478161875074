<template>
  <v-row no-gutters class="px-4 py-4">
    <v-col cols="12">
      <v-expansion-panels variant="accordion">
        <v-expansion-panel
          v-for="(svr, Index) in SupportVectorRegression"
          :key="Index"
        >
          <v-expansion-panel-title
            expand-icon="mdi-chevron-right"
            collapse-icon="mdi-chevron-down"
          >
            <div class="well-data">
              <div class="well-content">
                <img
                  :src="svr_image"
                  alt="Panel Image"
                  class="mr-2"
                  width="44"
                  height="44"
                />
                <div>
                  <div class="title">{{ svr.name }}</div>
                  <div class="desc">{{ svr.description }}</div>
                </div>
              </div>
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-content>
            <v-expansion-panel-text
              v-for="(section, sIndex) in svr.sections"
              :key="sIndex"
            >
              <router-link v-if="section.route" :to="section.route">
                <div class="pl-9 well-data">
                  <div class="well-content">
                    <img
                      :src="svr_sub_image"
                      alt="Section Image"
                      class="mr-2"
                      width="24"
                      height="24"
                    />
                    <div>
                      <div class="title mb-1">{{ section.title }}</div>
                      <div class="desc">{{ section.description }}</div>
                    </div>
                  </div>
                </div>
              </router-link>
              <v-divider v-if="sIndex !== svr.sections.length - 1"></v-divider>
            </v-expansion-panel-text>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SupportVectorRegression",
  data() {
    return {
      svr_image: require("../../../assets/Images/Assets/wells.png"),
      svr_sub_image: require("../../../assets/Images/Assets/sample.png"),
      SupportVectorRegression: [
        {
          name: "Support Vector Regression",
          description:
            "Description Meta Data? this section can contains summary/description/metadata etc.",
          sections: [
            {
              title: "Black Oil Table",
              description: "Description for Section 1",
              route:
                "/ai-ml-modelling/define-inputs/upload-reports/Supervisod-ml-models/support-vector-regression/black-oil-table",
            },
            {
              title: "Selevcted PVT Properties",
              description: "Description for Section 1",
              route:
                "/ai-ml-modelling/define-inputs/upload-reports/Supervisod-ml-models/support-vector-regression/selected-pvt-report",
            },
            {
              title: "Full PVT Properties",
              description: "Description for Section 1",
              route:
                "/ai-ml-modelling/define-inputs/upload-reports/Supervisod-ml-models/support-vector-regression/full-pvt-report",
            },
          ],
        },
      ],
    };
  },
};
</script>