<template>
    <SampleAssets />
</template>

<script>
import SampleAssets from '@/components/layout/SingleSampleFlow/SampleAssets.vue';
export default {
    name: "Assets",
    components: { SampleAssets },
};
</script>