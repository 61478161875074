<template>
  <BubbleChart class="mx-4" />
</template>

<script>
import BubbleChart from "../Charts/BubbleChart.vue"
export default {
    name: "InsightCharts",
    components: {
        BubbleChart
    }
}
</script>