<template>
  <StudioData />
</template>
    
<script>
import StudioData from "../components/layout/FluidsDataStudio/StudioData.vue";
export default {
  name: "FluidsDataStudio",
  components: {
    StudioData,
  },
};
</script>