<template>
  <div class="correlation-tables">
    <v-table>
      <thead>
        <tr>
          <th class="text-center">Relationship</th>
          <th class="text-center">Correlation Coefficient</th>
          <th class="text-center">Details</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in sorted" :key="item.name"></tr>
      </tbody>
    </v-table>
  </div>
  <v-chip class="mt-4" label style="width: 100%; font-weight: 600">
    Correlation Coffiecients - Sorted from Highest to Lowest
  </v-chip>
  <v-table>
        <thead>
          <tr>
            <th class="text-center">Variable</th>
            <th class="text-center">GOR</th>
            <th class="text-center">Density @ Psat</th>
            <th class="text-center">API Gravity</th>
            <th class="text-center">Psat</th>
            <th class="text-center">Bo @ Psat</th>
            <th class="text-center">Gas Gravity</th>
            <th class="text-center">Viscosity @ Psat</th>
            <th class="text-center">N2, mol%</th>
            <th class="text-center">H2S, mol%</th>
            <th class="text-center">CO2, mol%</th>
            <th class="text-center">C1, mol%</th>
            <th class="text-center">C2, mol%</th>
            <th class="text-center">C3, mol%</th>
            <th class="text-center">C7 + , mol%</th>
            <th class="text-center">C12 + , mol%</th>
            <th class="text-center">C20 + , mol%</th>
            <th class="text-center">C36 + , mol%</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in matrix" :key="item.name"></tr>
        </tbody>
      </v-table>

</template>

<script>
export default {
    name: "CorrelationComposition",
    correlationHeaders: ["Relationship", "Correlation Coefficient", "Details"],
      matrixHeaders: [
        "Variable", "GOR", "Density @ Psat", "API Gravity", "Psat",
        "Bo @ Psat", "Gas Gravity", "Viscosity @ Psat", "N2, mol%",
        "H2S, mol%", "CO2, mol%", "C1, mol%", "C2, mol%", "C3, mol%",
        "C7 + , mol%", "C12 + , mol%", "C20 + , mol%", "C36 + , mol%"
      ],
    matrix: [],
    sorted: [],
};
</script>

<style>
</style>