<template>
  <div>
    <div class="preview-table-wrap">
      <div class="preview-table">
        <h6>Enter Fluid Composition Data</h6>
        <v-data-table-server
          v-model:items-per-page="itemsPerPage"
          :headers="headers"
          :items-length="totalItems"
          :items="serverItems"
          :loading="loading"
          :search="search"
          item-value="name"
          @update:options="loadItems"
        ></v-data-table-server>
      </div>
      <div class="preview-table">
        <h6>Enter CCE Data</h6>
        <v-data-table-server
          v-model:items-per-page="itemsPerPage"
          :headers="Cceheaders"
          :items-length="totalItems"
          :items="serverItems"
          :loading="loading"
          :search="search"
          item-value="name"
          @update:options="loadItems"
        ></v-data-table-server>
      </div>
    </div>
    <div class="text-center btn-wrap">
      <v-btn class="primary-btn mb-4 mx-auto" @click="showModels()">
        <span> Run AL-ML Models </span>
      </v-btn>
    </div>
  </div>
</template>
<script>
const desserts = [
  {
    name: "Frozen Yogurt",
    calories: 159,
    fat: 6.0,
    carbs: 24,
    protein: 4.0,
    iron: "1",
  },
  {
    name: "Jelly bean",
    calories: 375,
    fat: 0.0,
    carbs: 94,
    protein: 0.0,
    iron: "0",
  },
  {
    name: "KitKat",
    calories: 518,
    fat: 26.0,
    carbs: 65,
    protein: 7,
    iron: "6",
  },
  {
    name: "Eclair",
    calories: 262,
    fat: 16.0,
    carbs: 23,
    protein: 6.0,
    iron: "7",
  },
  {
    name: "Gingerbread",
    calories: 356,
    fat: 16.0,
    carbs: 49,
    protein: 3.9,
    iron: "16",
  },
  {
    name: "Ice cream sandwich",
    calories: 237,
    fat: 9.0,
    carbs: 37,
    protein: 4.3,
    iron: "1",
  },
  {
    name: "Lollipop",
    calories: 392,
    fat: 0.2,
    carbs: 98,
    protein: 0,
    iron: "2",
  },
  {
    name: "Cupcake",
    calories: 305,
    fat: 3.7,
    carbs: 67,
    protein: 4.3,
    iron: "8",
  },
  {
    name: "Honeycomb",
    calories: 408,
    fat: 3.2,
    carbs: 87,
    protein: 6.5,
    iron: "45",
  },
  {
    name: "Donut",
    calories: 452,
    fat: 25.0,
    carbs: 51,
    protein: 4.9,
    iron: "22",
  },
];

const FakeAPI = {
  async fetch({ page, itemsPerPage, sortBy }) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const start = (page - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        const items = desserts.slice();

        if (sortBy.length) {
          const sortKey = sortBy[0].key;
          const sortOrder = sortBy[0].order;
          items.sort((a, b) => {
            const aValue = a[sortKey];
            const bValue = b[sortKey];
            return sortOrder === "desc" ? bValue - aValue : aValue - bValue;
          });
        }

        const paginated = items.slice(start, end);

        resolve({ items: paginated, total: items.length });
      }, 500);
    });
  },
};

export default {
  name: "PreviewTables",
  data: () => ({
    itemsPerPage: 10,
    headers: [
      {
        title: "Component",
        align: "center",
        key: "component",
      },
      { title: "MW", key: "mw", align: "center" },
      { title: "Monophase", key: "monophase", align: "center" },
    ],
    Cceheaders: [
      {
        title: "Pressure",
        align: "center",
        key: "pressure_1",
      },
      {
        title: "Pressure",
        align: "center",
        key: "pressure_2",
      },
      {
        title: "Relative",
        align: "center",
        key: "relative",
      },
      {
        title: "Y-Function",
        align: "center",
        key: "y-function",
      },
      {
        title: "Density",
        align: "center",
        key: "density",
      },
      {
        title: "Compressibility",
        align: "center",
        key: "compressibility",
      },
    ],
    search: "",
    serverItems: [],
    loading: true,
    totalItems: 0,
  }),
  methods: {
    loadItems({ page, itemsPerPage, sortBy }) {
      this.loading = true;
      FakeAPI.fetch({ page, itemsPerPage, sortBy }).then(({ items, total }) => {
        this.serverItems = items;
        this.totalItems = total;
        this.loading = false;
      });
    },
    showModels() {
      this.$router.push({ name: "AIMLModels" });
    },
  },
};
</script>