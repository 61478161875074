<template>
    <DataSelect />
  </template>
      
  <script>
  import DataSelect from "../../components/layout/AIML/DataSelect.vue";
  export default {
    components: { DataSelect },
    name: "SelectData",
  };
  </script>