<template>
  <CceData />
</template>
    
<script>
import CceData from "../ConstantCompositionExpansion/CceData.vue";
export default {
  components: { CceData },
  name: "Data",
};
</script>