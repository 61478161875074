<template>
    <div>
      <v-tabs v-model="tab">
        <v-tab value="one">Correletion Anaylsis - PVT Properties</v-tab>
        <v-tab value="two">Correlation Analysis - Composition</v-tab>
      </v-tabs>
  
      <v-card-text>
        <v-window v-model="tab">
          <v-window-item value="one">
            <CofficientMatrix />
          </v-window-item>
  
          <v-window-item value="two">
            <CorrelationComposition />
          </v-window-item>
        </v-window>
      </v-card-text>
    </div>
  </template>
  
  <script>

  import CofficientMatrix from './CofficientMatrix.vue'
  import CorrelationComposition from './CorrelationComposition.vue'
    export default {
    components: { CofficientMatrix, CorrelationComposition },
      name: "CorrelationTabs",
      data: () => ({
        tab: null,
      }),
    }
  </script>