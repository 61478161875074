import { createStore } from "vuex";
import auth from "./auth";
import tenantId from './tenantId';
import asset from './asset';
import field from './field';
import well from "./well";
import test from "./test";
import metaData from "./metaData";
import api from "./api"

export default createStore({
    state: {
        errors: []
    },

    getters: {
        errors: state => state.errors
    },

    mutations: {
        setErrors(state, errors) {
            state.errors = errors;
        }
    },

    actions: {},

    modules: {
        auth,
        tenantId,
        asset,
        field,
        well,
        test,
        metaData,
        api
    },
});
