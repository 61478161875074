<template>
  <div class="workspace-wrap">
    <div class="workspace">
      <span>Default Workspace</span>
      <v-avatar size="20">
        <v-img src="../../../assets/Images/Navbar/edit.png" alt="edit-workspace"></v-img>
      </v-avatar>
    </div>
    <div class="add-workspace">
      <v-avatar size="20">
        <v-img src="../../../assets/Images/Navbar/add.png" alt="add workspace"></v-img>
      </v-avatar>
    </div>
  </div>
</template>

<script>
export default {
  name: "Workspace",
};
</script>