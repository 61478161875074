<template>
    <SelectedPVT />
  </template>
        
    <script>
  import SelectedPVT from "../../components/layout/AIML/SelectedPVT.vue";
  export default {
    components: { SelectedPVT },
    name: "SelectedPVTReport",
  };
  </script>