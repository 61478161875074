<template>
  <v-expansion-panels class="mb-4" variant="accordion">
    <v-expansion-panel v-for="(panel, pIndex) in panelsData" :key="pIndex">
      <v-expansion-panel-title
        expand-icon="mdi-chevron-right"
        collapse-icon="mdi-chevron-down"
      >
        <div class="well-data">
          <div class="well-content">
            <img
              :src="panel.image"
              alt="Panel Image"
              class="mr-2"
              width="44"
              height="44"
            />
            <div>
              <div class="title">{{ panel.title }}</div>
              <div class="desc">{{ panel.titleDescription }}</div>
            </div>
          </div>
          <span class="sample-text"
            >{{ panel.sections.length }}
            {{ panel.sections.length > 1 ? "Samples" : "Sample" }}</span
          >
        </div>
      </v-expansion-panel-title>
      <v-expansion-panel-content>
        <v-expansion-panel-text
          v-for="(section, sIndex) in panel.sections"
          :key="sIndex"
        >
          <div class="pl-9 well-data">
            <div class="well-content">
              <img
                :src="section.image"
                alt="Section Image"
                class="mr-2"
                width="24"
                height="24"
              />
              <div>
                <div class="title mb-1">{{ section.title }}</div>
                <div class="desc">{{ section.description }}</div>
              </div>
            </div>
            <span class="sample-text">
              {{ sIndex + 1 }} {{ sIndex + 1 > 1 ? "Tests" : "Test" }}
            </span>
          </div>
          <v-divider v-if="sIndex !== panel.sections.length - 1"></v-divider>
        </v-expansion-panel-text>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
    
    <script>
export default {
  name: "OilTestAssets",
  data() {
    return {
      panelsData: [
        {
          title: "Item 1",
          titleDescription: "Description for Item 1",
          image: require("../../../assets/Images/Assets/wells.png"),
          sections: [
            {
              title: "Section 1",
              description: "Description for Section 1",
              text: "Section 1 Lorem ipsum dolor sit amet...",
              image: require("../../../assets/Images/Assets/sample.png"),
            },
          ],
        },
        {
          title: "Item 1",
          titleDescription: "Description for Item 1",
          image: require("../../../assets/Images/Assets/wells.png"),
          sections: [
            {
              title: "Section 1",
              description: "Description for Section 1",
              text: "Section 1 Lorem ipsum dolor sit amet...",
              image: require("../../../assets/Images/Assets/sample.png"),
            },
            {
              title: "Section 2",
              description: "Description for Section 1",
              text: "Section 2 Ut enim ad minim veniam...",
              image: require("../../../assets/Images/Assets/sample.png"),
            },
            {
              title: "Section 3",
              description: "Description for Section 1",
              text: "Section 3 Ut enim ad minim veniam...",
              image: require("../../../assets/Images/Assets/sample.png"),
            },
          ],
        },
        {
          title: "Item 2",
          titleDescription: "Description for Item 2",
          image: require("../../../assets/Images/Assets/wells.png"),
          sections: [
            {
              title: "Section 1",
              description: "Description for Section 1",
              text: "Section 1 Lorem ipsum dolor sit amet...",
              image: require("../../../assets/Images/Assets/sample.png"),
            },
            {
              title: "Section 2",
              description: "Description for Section 1",
              text: "Section 2 Ut enim ad minim veniam...",
              image: require("../../../assets/Images/Assets/sample.png"),
            },
            {
              title: "Section 3",
              description: "Description for Section 1",
              text: "Section 3 Ut enim ad minim veniam...",
              image: require("../../../assets/Images/Assets/sample.png"),
            },
            {
              title: "Section 1",
              description: "Description for Section 1",
              text: "Section 1 Lorem ipsum dolor sit amet...",
              image: require("../../../assets/Images/Assets/sample.png"),
            },
            {
              title: "Section 2",
              description: "Description for Section 1",
              text: "Section 2 Ut enim ad minim veniam...",
              image: require("../../../assets/Images/Assets/sample.png"),
            },
            {
              title: "Section 3",
              description: "Description for Section 1",
              text: "Section 3 Ut enim ad minim veniam...",
              image: require("../../../assets/Images/Assets/sample.png"),
            },
          ],
        },
      ],
    };
  },
};
</script>
    