export default {
    namespaced: true,
    state: {
        snackbar: {
            visible: false,
            message: '',
            color: 'red',
            timeout: 7000,
        },
    },
    mutations: {
        showSnackbar(state, { message, color, timeout }) {
            state.snackbar.message = message;
            state.snackbar.color = color;
            state.snackbar.timeout = timeout;
            state.snackbar.visible = true;
        },
        hideSnackbar(state) {
            state.snackbar.visible = false;
        },
    },
    actions: {
        showSnackbar({ commit }, payload) {
            commit('showSnackbar', payload);
        },
    },
};