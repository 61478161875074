<template>
    <SupportVectorRegression />
  </template>
      
  <script>
  import SupportVectorRegression from "../../components/layout/AIML/SupportVectorRegression.vue";
  export default {
    components: { SupportVectorRegression },
    name: "SupervisedMLModels",
  };
  </script>