<template>
  <v-row no-gutters class="px-4 py-4">
    <v-col cols="12">
      <v-expansion-panels variant="accordion">
        <v-expansion-panel v-for="(fd, Index) in FluidsdataModel" :key="Index">
          <v-expansion-panel-title
            expand-icon="mdi-chevron-right"
            collapse-icon="mdi-chevron-down"
          >
            <div class="well-data">
              <div class="well-content">
                <img
                  :src="fd_image"
                  alt="Panel Image"
                  class="mr-2"
                  width="44"
                  height="44"
                />
                <div>
                  <div class="title">{{ fd.name }}</div>
                  <div class="desc">{{ fd.description }}</div>
                </div>
              </div>
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-content>
            <v-expansion-panel-text
              v-for="(section, sIndex) in fd.sections"
              :key="sIndex"
            >
              <router-link v-if="section.route" :to="section.route">
                <div class="pl-9 well-data">
                  <div class="well-content">
                    <img
                      :src="fd_sub_image"
                      alt="Section Image"
                      class="mr-2"
                      width="24"
                      height="24"
                    />
                    <div>
                      <div class="title mb-1">{{ section.title }}</div>
                      <div class="desc">{{ section.description }}</div>
                    </div>
                  </div>
                </div>
              </router-link>
              <v-divider v-if="sIndex !== fd.sections.length - 1"></v-divider>
            </v-expansion-panel-text>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>
  
  <script>
export default {
  name: "SupportVectorRegression",
  data() {
    return {
      fd_image: require("../../../assets/Images/Assets/wells.png"),
      fd_sub_image: require("../../../assets/Images/Assets/sample.png"),
      FluidsdataModel: [
        {
          name: "Fluidsdata AI Model",
          description:
            "Description Meta Data? this section can contains summary/description/metadata etc.",
          sections: [
            {
              title: "Black Oil Table",
              description: "Description for Section 1",
              route:
                "/ai-ml-modelling/define-inputs/upload-reports/Fluidsdata-ai-model/black-oil-table",
            },
            {
              title: "Selevcted PVT Properties",
              description: "Description for Section 1",
              route:
                "/ai-ml-modelling/define-inputs/upload-reports/Fluidsdata-ai-model/selected-pvt-report",
            },
            {
              title: "Full PVT Properties",
              description: "Description for Section 1",
              route:
                "/ai-ml-modelling/define-inputs/upload-reports/Fluidsdata-ai-model/full-pvt-report",
            },
          ],
        },
      ],
    };
  },
};
</script>