<template>
    <OilTestAssets />
</template>

<script>
import OilTestAssets from "../../components/layout/SingleSampleFlow/OilTestAssets.vue"
export default {
    name: "OilTest",
    components: { OilTestAssets },
};
</script>