<template>
    <CvdTabs />
  </template>
      
  <script>
  import CvdTabs from "../components/layout/ContinousVolumeDepletion/CvdTabs.vue";
  export default {
    components: { CvdTabs },
    name: "CvdTest",
  };
  </script>