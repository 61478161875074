<template>
    <div>
      <v-tabs v-model="tab">
        <v-tab value="one">Data Inventory Analysis - Compositions</v-tab>
        <v-tab value="two">Data Inventory Analysis - PVT Properties</v-tab>
      </v-tabs>
  
      <v-card-text>
        <v-window v-model="tab">
          <v-window-item value="one">
            <DataInventoryAnalysisComposition />
          </v-window-item>
  
          <v-window-item value="two">
            <DataInventoryAnalysisPvt />
          </v-window-item>
        </v-window>
      </v-card-text>
    </div>
  </template>
  
  <script>
  import DataInventoryAnalysisComposition from './DataInventoryAnalysisComposition.vue';
  import DataInventoryAnalysisPvt from './DataInventoryAnalysisPvt.vue';
    export default {
    components: { DataInventoryAnalysisComposition,
      DataInventoryAnalysisPvt},
      name: "DataInventoryAnalysisTabs",
      data: () => ({
        tab: null,
      }),
    }
  </script>