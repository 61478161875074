<template>
    <DataEntry />
  </template>
      
  <script>
  import DataEntry from "../../components/layout/AIML/DataEntry.vue";
  export default {
    components: { DataEntry },
    name: "ManualDataEntry",
  };
  </script>