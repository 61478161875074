<template>
    <Reports />
  </template>
      
  <script>
  import Reports from "../../components/layout/AIML/Reports.vue";
  export default {
    components: { Reports },
    name: "UploadReports",
  };
  </script>