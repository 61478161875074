<template>
  <DataInventory />
</template>

<script>
import DataInventory from '../../components/layout/EDA/DataInventory.vue'
export default {
  components: { DataInventory },

}
</script>