<template>
  <div class="auth-wrapper d-block d-sm-flex">
    <Left />
    <LoginSide />
  </div>
</template>

<script>
import LoginSide from "../../components/layout/AuthRight/LoginSide.vue";
import Left from "../../components/layout/AuthLeft/Left.vue";
export default {
  name: "Login",
  components: { Left, LoginSide },
};
</script>