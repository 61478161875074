<template>
    <Models />
  </template>
      
  <script>
  import Models from "../../components/layout/AIML/Models.vue";
  export default {
    components: { Models },
    name: "AIMLModels",
  };
  </script>