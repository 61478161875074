<template>
    <v-row no-gutters class="px-4 py-4">
      <v-col cols="12">
        <v-expansion-panels variant="accordion">
          <v-expansion-panel
            v-for="(pvt, Index) in Full_PVT"
            :key="Index"
          >
            <v-expansion-panel-title
              expand-icon="mdi-chevron-right"
              collapse-icon="mdi-chevron-down"
            >
              <div class="well-data">
                <div class="well-content">
                  <img
                    :src="pvt_image"
                    alt="Panel Image"
                    class="mr-2"
                    width="44"
                    height="44"
                  />
                  <div>
                    <div class="title">{{ pvt.name }}</div>
                    <div class="desc">{{ pvt.description }}</div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-content>
              <v-expansion-panel-text
                v-for="(section, sIndex) in pvt.sections"
                :key="sIndex"
              >
                  <div class="pl-9 well-data">
                    <div class="well-content">
                      <img
                        :src="pvt_sub_image"
                        alt="Section Image"
                        class="mr-2"
                        width="24"
                        height="24"
                      />
                      <div>
                        <div class="title mb-1">{{ section.title }}</div>
                        <div class="desc">{{ section.description }}</div>
                      </div>
                    </div>
                  </div>
                <v-divider v-if="sIndex !== pvt.sections.length - 1"></v-divider>
              </v-expansion-panel-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
    name: "FullPVT",
    data() {
      return {
        openPanels: [0],
        pvt_image: require("../../../assets/Images/Assets/wells.png"),
        pvt_sub_image: require("../../../assets/Images/Assets/sample.png"),
        Full_PVT: [
          {
            name: "Full PVT Report",
            description:
              "Description Meta Data? this section can contains summary/description/metadata etc.",
            sections: [
              {
                title: "Constant Composition Expansion (CCE)",
                description: "Description for Section 1",
              },
              {
                title: "Differential Liberation (DL)",
                description: "Description for Section 1",
              },
              {
                title: "Separator Test",
                description: "Description for Section 1",
              },
              {
                title: "Viscosity",
                description: "Description for Section 1",
              }
            ],
          },
        ],
      };
    },
  };
  </script>