<template>
  <div class="data-provided-wrapper">
    <div class="wrapper-content">
      <h6 class="main-heading">Input Values Manually</h6>
      <div>
        <label for="compositions">Composition Selector (C36+)</label>
        <v-select placeholder="Select Composition" :items="items"></v-select>
      </div>
      <div class="input-wrap">
        <div>
          <label for="">Reservior Pressure</label>
          <v-text-field placeholder="Enter value"></v-text-field>
        </div>
        <div>
          <label for="">Reservior Temperature</label>
          <v-text-field placeholder="Enter value"></v-text-field>
        </div>
      </div>
      <v-btn append-icon="mdi-arrow-right" class="primary-btn">
        Input Composition/CCE Data
        <template v-slot:append>
          <v-icon color="white"></v-icon>
        </template>
      </v-btn>
    </div>
  </div>
</template>
    
    <script>
export default {
  name: "DataEntry",
  data: () => ({
    items: ["California", "Colorado", "Florida", "Georgia", "Texas", "Wyoming"],
  }),
};
</script>