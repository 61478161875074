<template>
    <CofficientChart />
  </template>
    
  <script>
  import CofficientChart from "../../components/layout/EDA/Correlation/CofficientChart.vue";
  export default {
    name: "CorrelationCoefficient",
    components: {
      CofficientChart,
    },
  };
  </script>