export default {
    namespaced: true,
    state: {
      wellData: null,
      loading: false,
    },
    mutations: {
      setWellData(state, data) {
        state.wellData = data;
        state.loading = false; 
      },
      setLoading(state, loading) {
        state.loading = loading;
      },
    },
    actions: {
      setWellData({ commit }, data) {
        commit('setWellData', data);
      },
      setLoading({ commit }, loading) {
        commit('setLoading', loading);
      },
    },
    getters: {
      getWellData: (state) => state.wellData,
      isLoading: (state) => state.loading,
    },
  };