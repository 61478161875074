<template>
    <DataProvided />
  </template>
      
  <script>
  import DataProvided from "../../components/layout/AIML/DataProvided.vue";
  export default {
    components: { DataProvided },
    name: "AIMLModelling",
  };
  </script>