<template>
    <FullPVT />
  </template>
        
    <script>
  import FullPVT from "../../components/layout/AIML/FullPVT.vue";
  export default {
    components: { FullPVT },
    name: "FullPVTReport",
  };
  </script>