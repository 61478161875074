<template>
  <Data />
</template>

<script>
import Data from "@/components/layout/ConstantCompositionExpansion/Data.vue";
export default {
  components: { Data },
  name: "ConstantCompositionExpansion",
};
</script>