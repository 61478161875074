<template>
    <Sample />
</template>

<script>
import Sample from '@/components/layout/SingleSampleFlow/Sample.vue';
export default {
    name: "Samples",
    components: { Sample },
};
</script>