<template>
    <GasTestAssets />
</template>

<script>
import GasTestAssets from '../../components/layout/SingleSampleFlow/GasTestAssets.vue';
export default {
    name: "GasTest",
    components: { GasTestAssets },
};
</script>