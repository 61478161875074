<template>
  <DataTabs />
</template>
  
<script>
import DataTabs from "../components/layout/DifferentialLiberation/DataTabs.vue";
export default {
  name: "DifferentialLiberationSection",
  components: {
    DataTabs,
  },
};
</script>