<template>
    <SampleFields />
</template>

<script>
import SampleFields from '@/components/layout/SingleSampleFlow/SampleFields.vue';
export default {
    name: "Fields",
    components: { SampleFields },
};
</script>