<template>
    <BoardSelected />
  </template>
        
    <script>
  import BoardSelected from '@/components/layout/Dashboard/BoardSelected.vue';
  export default {
    components: { BoardSelected },
    name: "Board",
  };
  </script>