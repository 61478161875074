<template>
  <div class="data-provided-wrapper">
    <div class="wrapper-content">
      <h6 class="main-heading">Data Provided...</h6>
      <div class="data-options">
        <div class="option-wrapper" @click="goToInputs()">
          <img :src="documentUpload" alt="" />
          <h6>Fluid Composition Only</h6>
        </div>
        <div class="option-wrapper">
          <img :src="category" alt="" />
          <h6>Fluid Composition + CEE</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataProvided",
  data: () => ({
    documentUpload: require("../../../assets/Images/AIML/document-upload.png"),
    category: require("../../../assets/Images/AIML/category-2.png"),
  }),
  methods: {
    goToInputs() {
      this.$router.push({ name: "DataInputs" });
    },
  },
};
</script>