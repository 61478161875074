<template>
    <FluidsdataModel />
  </template>
      
  <script>
  import FluidsdataModel from "../../components/layout/AIML/FluidsdataModel.vue";
  export default {
    components: { FluidsdataModel },
    name: "FluidsdataAIModel",
  };
  </script>