<template>
    <SampleWells />
</template>

<script>
import SampleWells from '@/components/layout/SingleSampleFlow/SampleWells.vue';
export default {
    name: "Wells",
    components: { SampleWells },
};
</script>