<template>
    <CompositionTabs />
  </template>
      
  <script>
  import CompositionTabs from "../components/layout/CompositionAnalysis/CompositionTabs.vue";
  export default {
    components: { CompositionTabs },
    name: "Composition",
  };
  </script>