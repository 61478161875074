<template>
  <v-app>
    <UniversalLayout />
    <v-snackbar v-model="showSnackbar" :timeout="5000" :color="snackbarColor">{{ snackbarMessage }}</v-snackbar>
  </v-app>
</template>

<script>
import UniversalLayout from "./components/layout/Common/UniversalLayout.vue";
import { mapActions } from "vuex";

export default {
  name: 'App',
  components: {
    UniversalLayout,
  },
  data() {
    return {
      showSnackbar: false,
      snackbarMessage: '',
      snackbarColor: 'success',
    };
  },
  methods: {
    ...mapActions("tenantId", ["getUserData"]),
    showOnlineSnackbar() {
      this.snackbarMessage = 'Back Online';
      this.showSnackbar = true;
      this.snackbarColor = 'success';
    },
    showOfflineSnackbar() {
      this.snackbarMessage = 'You are currently offline. Please check your internet connection.';
      this.showSnackbar = true;
      this.snackbarColor = 'error';
    },
  },
   mounted() {
    if (localStorage.getItem("authToken")) {
      this.getUserData();
    }
    if (!navigator.onLine) {
      this.showOfflineSnackbar();
    }
    window.addEventListener('offline', this.showOfflineSnackbar);
    window.addEventListener('online', this.showOnlineSnackbar);
  },
}
</script>
