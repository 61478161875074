<template>
  <BlackOil />
</template>
      
  <script>
import BlackOil from "../../components/layout/AIML/BlackOil.vue";
export default {
  components: { BlackOil },
  name: "BlackOilTable",
};
</script>