<template>
    <CorrelationTabs />
  </template>
    
  <script>
  import CorrelationTabs from "../../components/layout/EDA/Correlation/CorrelationTabs.vue";
  export default {
    name: "CorrelationAnalysis",
    components: {
      CorrelationTabs,
    },
  };
  </script>