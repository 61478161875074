export default {
  namespaced: true,
  state: {
    assetData: null,
    loading: false,
  },
  mutations: {
    setAssetData(state, data) {
      state.assetData = data;
      state.loading = false; 
      localStorage.setItem('assetData', JSON.stringify(data));
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    initializeStore(state) {
      const storedAssetData = localStorage.getItem('assetData');
      if (storedAssetData) {
        state.assetData = JSON.parse(storedAssetData);
      }
    },
  },
  actions: {
    setAssetData({ commit }, data) {
      commit('setAssetData', data);
    },
    setLoading({ commit }, loading) {
      commit('setLoading', loading);
    },
    initializeStore({ commit }) {
      commit('initializeStore');
    },
  },
  getters: {
    getAssetData: (state) => state.assetData,
    isLoading: (state) => state.loading,
  },
};