export default {
    namespaced: true,
    state: {
      fieldData: null,
      loading: false,
    },
    mutations: {
      setFieldData(state, data) {
        state.fieldData = data;
        state.loading = false; 
        localStorage.setItem('fieldData', JSON.stringify(data));
      },
      setLoading(state, loading) {
        state.loading = loading;
      },
      initializeStore(state) {
        const storedFieldData = localStorage.getItem('fieldData');
        if (storedFieldData) {
          state.fieldData = JSON.parse(storedFieldData);
        }
      },
    },
    actions: {
      setFieldData({ commit }, data) {
        commit('setFieldData', data);
      },
      setLoading({ commit }, loading) {
        commit('setLoading', loading);
      },
      initializeStore({ commit }) {
        commit('initializeStore');
      },
    },
    getters: {
      getFieldData: (state) => state.fieldData,
      isLoading: (state) => state.loading,
    },
  };