export default {
    namespaced: true,
  state: {
    activeTestIndex: null,
  },
  mutations: {
    setActiveTestIndex(state, index) {
      state.activeTestIndex = index;
      localStorage.setItem('activeTestIndex', index);
    },
  },
  actions: {
    initializeActiveTestIndex({ commit }) {
        const index = localStorage.getItem('activeTestIndex');
        if (index !== null) {
          commit('setActiveTestIndex', parseInt(index));
        }
      },
    setActiveTestIndex({ commit }, index) {
      commit('setActiveTestIndex', index);
    },
  },
  getters: {
    activeTestIndex(state) {
      return state.activeTestIndex;
    },
  },
};
