<template>
  <div class="my-3 mr-3 chart-loader">
    <v-skeleton-loader class="border" type="image"></v-skeleton-loader>
    <v-skeleton-loader class="border" type="image"></v-skeleton-loader>
  </div>
</template>
  
  <script>
export default {
  name: "ChartLoaders",
};
</script>
  