
  <template>
  <div class="data-provided-wrapper">
    <div class="wrapper-content">
      <div class="data-options">
        <v-card class="mx-auto pa-12" min-width="400" elevation="16" @click="showCard()" v-if="dragCard">
          <v-card-item>
            <v-card-title class="text-center">
              Drag Your Files Here
            </v-card-title>
          </v-card-item>
          <v-img :src="documentUpload" alt="document upload"></v-img>

          <v-card-text>
            <span>
                Upload your report data here(csv, PDF, xlsx)
            </span>
          </v-card-text>
        </v-card>
        <v-card class="mx-auto" min-width="400" elevation="16" min-height="175" v-if="uploadCard">
          <v-img :src="success" alt="successful"></v-img>
          <v-card-text>
            <h6>Upload! Successful</h6>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reports",
  data: () => ({
    documentUpload: require("../../../assets/Images/AIML/document-upload.png"),
    success: require("../../../assets/Images/AIML/success.png"),
    uploadCard: false,
    dragCard: true,
  }),
  methods: {
    showCard() {
        this.uploadCard = true;
        this.dragCard = false;
        setTimeout(() => {
            this.$router.push({name: "DataPreview"})
        }, 2000);
    }
  }
};
</script>