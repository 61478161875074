<template>
    <DataInventoryAnalysisTabs />
</template>

<script>
import DataInventoryAnalysisTabs from '@/components/layout/EDA/DataInventoryAnalysisTabs.vue';
export default {
    name: "DataInventoryAnalysis",
    components: { DataInventoryAnalysisTabs },
};
</script>