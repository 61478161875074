<template>
    <Input />
  </template>
      
  <script>
  import Input from "../../components/layout/AIML/Input.vue";
  export default {
    components: { Input },
    name: "DataInputs",
  };
  </script>