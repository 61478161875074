<template>
  <div class="data-provided-wrapper data-provided-wrapper-models">
    <div class="wrapper-content">
      <div class="data-options">
        <v-card
          class="mx-auto mb-4"
          min-width="400"
          style="background-color: #e8e8e8"
        >
          <v-card-item class="pt-0">
            <v-card-title class="text-center"> AI / ML Models </v-card-title>
          </v-card-item>
          <v-table>
            <thead>
              <tr>
                <th class="text-center">Sr No.</th>
                <th class="text-center">Supervised ML Models</th>
                <th class="text-center">Results</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in models" :key="item.name">
                <td class="text-center sr_no">{{ item.serial }}</td>
                <td class="model-name">{{ item.name }}</td>
                <td class="text-center"><v-img class="check-img" :src="check" alt="check"></v-img></td>
              </tr>
            </tbody>
          </v-table>

          <v-btn class="auth-btn w-100 mt-4" @click="showSVR">
            <span>{{ buttonText }}</span></v-btn>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Models",
  data() {
    return {
      check: require("../../../assets/Images/AIML/tick-circle.png"),
      buttonText: "Running",
      models: [
        {
          serial: 1,
          name: "Fluidsdata AI Model",
        },
        {
          serial: 2,
          name: "Support Vector Regression (SVR)",
        },
        {
          serial: 3,
          name: "Random Forest",
        },
        {
          serial: 4,
          name: "Gradient Boosting",
        },
        {
          serial: 5,
          name: "Adaptive Boosting (AdaBoost)",
        },
        {
          serial: 6,
          name: "Nueral Networks",
        },
        {
          serial: 7,
          name: "Ensamble",
        },
        {
          serial: 8,
          name: "Bagging",
        },
        {
          serial: 9,
          name: "Voting With Random Forest And SVR",
        },
      ],
    };
  },
  methods: {
    showSVR() {
        this.buttonText = 'Running...';
        setTimeout(() => {
            this.$router.push({name: "SupervisedMLModels"})
        }, 2000);
    }
  }
};
</script>

<style>
</style>