<template>
  <div class="mt-4 mr-3">
    <v-skeleton-loader type="card"></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: "ScreenLoader",
};
</script>