<template>
  <v-dialog
    v-model="dialog"
    width="600"
    transition="dialog-top-transition"
    class="export-modal"
  >
    <template v-slot:default="{ isActive }">
      <v-card>
        <v-card-title class="d-flex align-center justify-between"
          >Select Export Format
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog"
            flat
            icon
            class="chart-preview-close-btn"
            size="x-small"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-4 mx-5">
          <v-select
            placeholder="Select Export Format"
            :items="items"
          ></v-select>
        </v-card-text>
        <v-card-actions class="btn-wrap d-block mx-3">
          <v-btn class="primary-btn" @click="isActive.value = false">
            <span>Export</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
  
  <script>
export default {
  name: "ExportModal",
  data() {
    return {
      dialog: false,
      items: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>