<template>
  <div class="correlation-tables">
    <div>
      <v-chip label style="width: 100%; font-weight: 600">
        Correlation Coffiecient Matrix
      </v-chip>
      <v-table>
        <thead>
          <tr>
            <th class="text-center">Variable</th>
            <th class="text-center">GOR</th>
            <th class="text-center">Density @ Psat</th>
            <th class="text-center">API Gravity</th>
            <th class="text-center">Psat</th>
            <th class="text-center">Bo @ Psat</th>
            <th class="text-center">Gas Gravity</th>
            <th class="text-center">Viscosity @ Psat</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in matrix" :key="item.name"></tr>
        </tbody>
      </v-table>
    </div>
    <div>
      <v-chip label style="width: 100%; font-weight: 600">
        Correlation Coffiecient Matrix
      </v-chip>
      <v-table>
        <thead>
          <tr>
            <th class="text-center">Relationship</th>
            <th class="text-center">Correlation Coefficient</th>
            <th class="text-center">Details</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in sorted" :key="item.name"></tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "CofficientMatrix",
  matrix: [],
  sorted: [],
};
</script>

<style>
</style>