<template>
  <div class="data-provided-wrapper">
    <div class="wrapper-content">
      <h6 class="main-heading">Define your Inputs</h6>
      <div class="data-options">
        <div class="option-wrapper" @click="goToReports()">
          <img :src="documentUpload" alt="" />
          <h6>Upload a xlsx, csv...</h6>
        </div>
        <div class="option-wrapper" @click="selectFromDatabase()">
          <img :src="category" alt="" />
          <h6>Select From Database</h6>
        </div>
        <div class="option-wrapper" @click="inputManually()">
          <img :src="edit" alt="" />
          <h6>Input values manually</h6>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Input",
  data: () => ({
    documentUpload: require("../../../assets/Images/AIML/document-upload.png"),
    category: require("../../../assets/Images/AIML/category-2.png"),
    edit: require("../../../assets/Images/AIML/edit.png"),
  }),
  methods: {
    goToReports() {
      this.$router.push({ name: "UploadReports" });
    },
    selectFromDatabase() {
      this.$router.push({ name: "SelectData" });
    },
    inputManually() {
      this.$router.push({name: "ManualDataEntry"})
    }
  },
};
</script>