<template>
    <div class="mr-2 chart-loader">
      <v-skeleton-loader class="border" type="image"></v-skeleton-loader>
    </div>
  </template>
    
    <script>
  export default {
    name: "SingleChartLoader",
  };
  </script>
    