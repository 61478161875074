<template>
    <v-row no-gutters class="ma-0">
      <v-col v-for="index in numberOfCols" :key="index" cols="12">
        <v-skeleton-loader type="list-item-three-line"> </v-skeleton-loader>
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
    name: "TestLoaders",
    data() {
      return {
        numberOfCols: 1,
      };
    },
  };
  </script>
  