<template>
  <div class="px-4">
    <div class="d-flex align-center">
      <label for="" class="mr-3 mb-3">Selected Model</label>
      <v-select
        v-model="selectedModel"
        placeholder="Select Model"
        class="mb-0"
        :items="modelItems"
        @update:modelValue="handleModelSelection()"
      ></v-select>
    </div>
    <v-chip
      class="d-flex justify-space-between"
      label
      style="width: 100%; font-weight: 600"
    >
      <div>PVT Properties based of fluid compositions</div>
      <img :src="documentDownload" alt="" @click="openDialog()" />
    </v-chip>
    <v-table>
      <thead>
        <tr>
          <th class="text-center">Temperature</th>
          <th class="text-center">Pressure</th>
          <th class="text-center">Bubble Point</th>
          <th class="text-center">Gas Oil Ratio</th>
          <th class="text-center">Oil FVF</th>
          <th class="text-center">Oil Viscosity</th>
          <th class="text-center">Z Factor</th>
          <th class="text-center">Gas FVF</th>
          <th class="text-center">Gas Viscosity</th>
          <th class="text-center">Oil Density</th>
          <th class="text-center">Gas Density</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in oil" :key="item.temperature">
          <td class="text-center">{{ item.temperature }}</td>
          <td class="text-center">{{ item.pressure }}</td>
          <td class="text-center">{{ item.bubble_point }}</td>
          <td class="text-center">{{ item.gas_oil_ratio }}</td>
          <td class="text-center">{{ item.oil_fvf }}</td>
          <td class="text-center">{{ item.oil_viscosity }}</td>
          <td class="text-center">{{ item.z_factor }}</td>
          <td class="text-center">{{ item.gas_fvf }}</td>
          <td class="text-center">{{ item.oil_viscosity }}</td>
          <td class="text-center">{{ item.oil_density }}</td>
          <td class="text-center">{{ item.gas_density }}</td>
        </tr>
      </tbody>
    </v-table>
    <ExportModal ref="childComponent" @open-dialog="openDialog" />
  </div>
</template>

<script>
import ExportModal from "./ExportModal.vue";
export default {
  name: "BlackOil",
  components: {
    ExportModal,
  },
  data() {
    return {
      documentDownload: require("../../../assets/Images/AIML/document-download.png"),
      selectedModel: [],
      modelItems: [
        "Fluidsdata AI Model",
        "Support Vector Regression (SVR)",
        "Random Forest",
        "Gradient Boosting",
        "Adaptive Boosting (AdaBoost)",
        "Nueral Networks",
        "Ensamble",
        "Bagging",
        "Voting With Random Forest And SVR",
      ],
      oil: [],
    };
  },
  methods: {
    handleModelSelection() {
      console.log(this.selectedModel);
    },
    openDialog() {
      if (this.$refs.childComponent) {
        this.$refs.childComponent.openDialog();
      } else {
        console.error("Child component not available");
      }
    },
  },
};
</script>