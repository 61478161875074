<template>
  <v-row no-gutters>
    <v-col cols="12" md="3">
      <StudioDataLeft />
    </v-col>
    <v-col cols="12" md="9">
      <GasCompComponentChart />
    </v-col>
  </v-row>
</template>

<script>
import StudioDataLeft from "../FluidsDataStudio/StudioDataLeft.vue";
import GasCompComponentChart from "../DifferentialLiberation/Charts/GasCompComponentChart.vue";
export default {
  components: { StudioDataLeft, GasCompComponentChart },
};
</script>