<template>
  <TwoDHistogramChart />
</template>

<script>
import TwoDHistogramChart from '../../Charts/TwoDHistogramChart.vue'
export default {
    name: "CoefficientChart",
    components: {
        TwoDHistogramChart
    }
}
</script>