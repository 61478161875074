<template>
  <div :ref="chartId"></div>
</template>
    
<script>
import Plotly from "plotly.js-dist-min";
export default {
  name: "BubbleChart",
  data() {
    return {
      chartId: 'bubbleChart'
    };
  },
  props: {
    xAxisData: { Array, default: () => [] },
    yAxisData: { Array, default: () => [] },
    markerSizes: Array,
  },
  watch: {
    xAxisData: function(newX, oldX) {
      this.plotChart();
    },
    yAxisData: function(newY, oldY) {
      this.plotChart();
    },
  },
  methods: {
    plotChart() {
      const traces = this.yAxisData.map((yData, index) => ({
        x: this.xAxisData,
        y: yData,
        name: `Bubble ${index + 1}`,
        mode: "markers",
        marker: {
          size: this.markerSizes,
        },
      }));

      var layout = {
        title: "Bubble Chart",
        yaxis: { title: "OilDensity" },
      };
      var config = {responsive: true}
      Plotly.newPlot(this.$refs[this.chartId], traces, layout, config);
    },
  },
  mounted() {
    this.plotChart();
  },
};
</script>
    